<template>
  <report-stock
    title="Bodega Móvil - Stock de Bodega Móvil"
    :mobileWarehousesStock="true"
  ></report-stock>
</template>

<script>
import ReportStock from "../../reports/ReportStock.vue";
export default {
  components: { ReportStock },
};
</script>

<style></style>
